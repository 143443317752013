import { ERules } from './rules';

export enum EDIVISIONS { // What is stored in DB
    TR = 'TR',
    FTR = 'FTR',
    FOPEN = 'FOPEN',
    FS = 'FS',
    AA = 'AA',
    AI = 'AI',
    SP = 'SP',
    SO = 'SO',
    PR = 'PR',
    SR = 'SR',
    HONOURS = 'Honours',
    FSTD = 'FSTD',
}

export const DCRADivisions = [
    { value: 'TR', label: 'TR' },
    { value: 'FTR', label: 'F-TR' },
    { value: 'FOPEN', label: 'F-Open' },
    { value: 'Honours', label: 'Honours' },
];

export const WIFSCDivisions = [
    { value: 'TR', label: 'TR' },
    { value: 'Honours', label: 'Honours' },
];

export const NRAADivisions = [
    { value: 'TR', label: 'Palma' },
    { value: 'FSTD', label: 'F-Standard' },
    { value: 'FOPEN', label: 'F-Open' },
    { value: 'FTR', label: 'F-TR' },
    { value: 'SO', label: 'Sporter - Open' },
    { value: 'SP', label: 'Sporter - Production' },
    { value: 'PR', label: 'Precision Rifle' },
    { value: 'SR', label: 'Service Rifle' },
];

export const NRAUSDivisions = [
    { value: 'TR', label: 'TR' },
    { value: 'FOPEN', label: 'F-Open' },
    { value: 'AA', label: 'Any Sights/Any Rifle' },
    { value: 'AI', label: 'Iron Sights/Any Rifle' },
    { value: 'FTR', label: 'F-TR' },
];

export const ODivisions = [
    { value: 'TR', label: 'TR (Palma)' },
    { value: 'FTR', label: 'F-TR' },
    { value: 'FOPEN', label: 'F-Open' },
    { value: 'FS', label: 'F-Standard' },
    { value: 'AA', label: 'Any Sights/Any Rifle' },
    { value: 'AI', label: 'Iron Sights/Any Rifle' },
    { value: 'FSTD', label: 'F-Standard' },
    { value: 'Honours', label: 'Honours' },
    { value: 'SO', label: 'Sporter - Open' },
    { value: 'SP', label: 'Sporter - Production' },
    { value: 'PR', label: 'Precision Rifle' },
    { value: 'SR', label: 'Service Rifle' },
];

export const getDivisionName = (id: EDIVISIONS | string) => {
    return ODivisions.find((item) => item.value === id)?.label || id;
};

export const getDivisionByRules = (rules: string | undefined) => {
    switch (true) {
        case rules === ERules.DCRA:
        case rules === ERules.NRAUK:
        case rules === ERules.ICFRA:
            return DCRADivisions;
        case rules === ERules.NRAA:
            return NRAADivisions;
        case rules === ERules.NRAUS:
            return NRAUSDivisions;
        case rules === ERules.WIFSC:
            return WIFSCDivisions;
        default:
            return DCRADivisions;
    }
};
