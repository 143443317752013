import PouchDB from 'pouchdb-browser';
import * as Yup from 'yup';
import { FormField } from '../../pages/Event/IBuildForm';
import { EAgeCat } from '../Models/age';
import { EDIVISIONS } from '../Models/divisions';
import { DB_URL, EVENTS_DB, PSWD, USN } from "../../utils/constants";

export interface ITeam {
    teamName: string;
    division: EDIVISIONS;
    teamId: string;
    shooters: Array<string>;
    coaches: Array<string>;
    captains: Array<string>;
    scoreKeepers: Array<string> | undefined;
    matches: Array<string>;
}

export interface IParticipants {
    competitorNumber: number;
    participant: string;
    classification?: string;
    repersentingCountry: string;
    ageCat?: EAgeCat;
    division: EDIVISIONS;
    fullname: string;
    specialCat?: string; // Custom List ID
    status?: 'Approved' | 'Pending' | 'NotPaid' | 'InfoMissing' | 'Disqualified' | 'Withdrew' | string;
}

export interface IMatch {
    type: EMatchType;
    warmup: boolean;
    matchName: string;
    matchDate: string;
    range: string;
    distance: number;
    shots: string;
    sighters: string;
    targetFace: string;
    matchId: string;
    desc: string;
}

export enum EMatchType {
    INDIVIDUAL = 'I',
    TEAM = 'T',
}

export interface IGroup {
    id: string;
    name: string;
    members: Array<string>;
}

export interface IAgg {
    id: string;
    name: string;
    desc: string;
    classifications: Array<string> | undefined;
    genders: Array<string> | undefined;
    ageCats: Array<string> | undefined;
    distances: Array<string> | undefined;
    specialCats: Array<string> | undefined;
    partiGroups: Array<string> | undefined;
    days: Array<string> | undefined;
    matchIds: Array<string> | undefined;
    mustShoot: Array<string> | undefined;
    bestOnly: true | undefined;
    noOfBest: number;
    countries: Array<string> | undefined;
    isDefault: true | undefined;
    orCondition?: boolean; // Pending
}

export interface ISpecialCategory {
    id: string;
    name: string;
}

export interface IEvent {
    club: string;
    clubName?: string;
    name: string;
    // unit: RangeUnit;
    rules: string;
    dates: Array<Date>;
    website: string;
    overview: string;
    howToEnter: string;
    emailMessage: string;
    email: string;
    fee: string;
    entryDates: Array<Date>;
    entryForm?: Array<FormField>;
    divisions?: Array<string>;
    owner: string;
    doc_type: 'event';
    matches?: Array<IMatch>;
    participants?: Array<IParticipants>;
    teams?: Array<ITeam>;
    groups: Array<IGroup>;
    hide?: boolean;
    hidden?: boolean;
    removed?: boolean;
    aggs: Array<IAgg>;
    specialCat: Array<ISpecialCategory>;
    notify: 'contactEmail' | 'managers' | 'other';
    otherEmail?: string;
    featParti: boolean;
    featScores: boolean;
    featMatches: boolean;
    ageAgg: boolean;
    genderAgg: boolean;
    classAgg: boolean;
    _id: string;
    _rev?: string;
}

export const EntrySchema = Yup.object({
    id: Yup.string().required(),
    competitorNumber: Yup.number().optional(),
    classification: Yup.string().when('classAgg', {
        is: true,
        then: Yup.string().required('Please select a valid classification.'),
        otherwise: Yup.string().notRequired(),
    }),
    repersentingCountry: Yup.string().required('Please select a valid country.'),
    ageCat: Yup.string().when('ageAgg', {
        is: true,
        then: Yup.string().required('Please choose a valid age group.'),
        otherwise: Yup.string().notRequired(),
    }),
    division: Yup.string().required('Please select a valid division.'),
    specialCat: Yup.string(),
    // For Register Only
    status: Yup.string().required(),
    // To FInd or Create the Athlete
    fullname: Yup.string().required('Please fill the name'),
    email: Yup.string().when('emailNA', {
        is: true,
        then: Yup.string().oneOf(['na']),
        otherwise: Yup.string().email().required('Please fill the email address'),
    }),
    gender: Yup.string().when('genderAgg', {
        is: true,
        then: Yup.string().required('Please choose a valid gender.'),
        otherwise: Yup.string().notRequired(),
    }),
    emailNA: Yup.boolean(),
    ageAgg: Yup.boolean(),
    genderAgg: Yup.boolean(),
    classAgg: Yup.boolean(),
});

export type IParticipant = Yup.InferType<typeof EntrySchema>;

export const eventDB = () =>
    new PouchDB<IEvent>(`${DB_URL}/${EVENTS_DB}`, {
        skip_setup: true,
        auth: { username: USN, password: PSWD },
        fetch: function (url, opts) {
            return PouchDB.fetch(url, opts);
        },
    });

export const getUpcomingEvents = async () => {
    const events = await eventDB().find({
        selector: {
            $and: [
                {
                    dates: {
                        $elemMatch: {
                            $gte: new Date().toISOString(),
                        },
                    },
                },
                {
                    doc_type: {
                        $eq: 'event',
                    },
                },
                {
                    $or: [
                        {
                            hidden: false,
                        },
                        {
                            removed: false,
                        },
                    ],
                },
            ],
        },
    });
    return events.docs?.sort((a, b) => Date.parse(a.dates[1]?.toString()) - Date.parse(b.dates[1]?.toString()));
};

export const getPastEvents = async () => {
    // eventDB().createIndex({
    //   index: { fields: ["doc_type"] },
    // });
    const events = await eventDB().find({
        selector: {
            $and: [
                {
                    dates: {
                        $elemMatch: {
                            $lte: new Date().toISOString(),
                        },
                    },
                },
                {
                    doc_type: {
                        $eq: 'event',
                    },
                },
                {
                    $or: [
                        {
                            hidden: false,
                        },
                        {
                            removed: false,
                        },
                    ],
                },
                {
                    $or: [{ featScores: { $exists: false } }, { featScores: true }],
                },
            ],
        },
        limit: 10000
    });
    return events.docs?.sort((a, b) => Date.parse(b.dates[1]?.toString()) - Date.parse(a.dates[1]?.toString()));
};

export const getClubEvents = async (club: string) => {
    const events = await eventDB().find({
        selector: {
            $and: [
                {
                    club: {
                        $eq: club,
                    },
                },
                {
                    doc_type: {
                        $eq: 'event',
                    },
                },
            ],
        },
    });
    return events.docs;
};

export const getUpcomingClubEvents = async (club: string, owner?: boolean) => {
    const events = await eventDB().find({
        selector: {
            $and: [
                {
                    dates: {
                        $elemMatch: {
                            $gte: new Date().toISOString(),
                        },
                    },
                },
                {
                    club: {
                        $eq: club,
                    },
                },
                {
                    doc_type: {
                        $eq: 'event',
                    },
                },
                ...(!owner
                    ? [
                          {
                              $or: [
                                  {
                                      hidden: false,
                                  },
                                  {
                                      removed: false,
                                  },
                              ],
                          },
                      ]
                    : []),
            ],
        },
    });
    return events.docs?.sort((a, b) => Date.parse(a.dates[1]?.toString()) - Date.parse(b.dates[1]?.toString()));
};

export const getPastClubEvents = async (club: string, owner?: boolean) => {
    const events = await eventDB().find({
        selector: {
            $and: [
                {
                    dates: {
                        $elemMatch: {
                            $lte: new Date().toISOString(),
                        },
                    },
                },
                {
                    club: {
                        $eq: club,
                    },
                },
                {
                    doc_type: {
                        $eq: 'event',
                    },
                },
                ...(!owner
                    ? [
                          {
                              $or: [
                                  {
                                      hidden: false,
                                  },
                                  {
                                      removed: false,
                                  },
                              ],
                          },
                      ]
                    : []),
            ],
        },
    });
    return events.docs?.sort((a, b) => Date.parse(b.dates[1]?.toString()) - Date.parse(a.dates[1]?.toString()));
};

export const getEventsByClubs = async (clubs: Array<string>) => {
    const events =
        clubs.length > 0 &&
        (await eventDB().find({
            selector: {
                $and: [
                    { doc_type: 'event' },
                    {
                        club: {
                            $or: clubs,
                        },
                    },
                ],
            },
        }));
    return events ? events.docs : [];
};

export const fetchAthleteEvents = async (user: string) => {
    const events =
        user &&
        (await eventDB().find({
            selector: {
                $and: [
                    {
                        dates: {
                            $elemMatch: {
                                $gte: new Date().toISOString(),
                            },
                        },
                    },
                    {
                        participants: {
                            $elemMatch: {
                                participant: user,
                            },
                        },
                    },
                ],
            },
        }));
    return events ? events.docs : [];
};


export const fetchPastEvents = async (user: string) => {
    const events =
        user &&
        (await eventDB().find({
            selector: {
                $and: [
                    {
                        dates: {
                            $elemMatch: {
                                $lte: new Date().toISOString(),
                            },
                        },
                    },
                    {
                        participants: {
                            $elemMatch: {
                                participant: user,
                            },
                        },
                    },
                ],
            },
        }));
    return events ? events.docs : [];
};