import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IEvent, clubDB, eventDB } from '../../db';
import { IRootState } from '../../store';
import { setEvent } from '../../store/eventReducer';
import { Dropdown } from '../Dropdown';
import { IconTrophy } from '../Icons';
import { toast } from "../Alerts/Toast";

interface Props {
    className?: string;
}

export const SelectedEvent: React.FC<Props> = ({ className }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        themeConfig,
        user: { user },
        event: { selectedEvent },
    } = useSelector((state: IRootState) => state);
    const isRtl = themeConfig?.rtlClass === 'rtl';
    const isAdmin = user.roles?.includes('admin');
    const [ownerEvents, setOwnerEvents] = useState<Array<IEvent>>([]);

    const fetchClubsAndEvents = useCallback(() => {
        if (user.metadata?.id) {
            // Find clubs, which has current user in owner list
            clubDB().createIndex({
                index: { fields: ['_id', 'owners'] },
            });
            eventDB().createIndex({
                index: { fields: ['doc_type', 'club'] },
            });
            clubDB()
                .find({
                    selector: {
                        _id: { $gt: null },
                        ...(!isAdmin && {
                            owners: {
                                $elemMatch: {
                                    id: user.metadata.id,
                                },
                            },
                        }),
                    },
                })
                .then(({ docs }) => {
                    if (docs?.length > 0) {
                        // TODO: Test multi role combination
                        let clubs = docs.map((doc) => doc._id);
                        eventDB()
                            .find({
                                selector: {
                                    $and: [
                                        {
                                            dates: {
                                                $elemMatch: {
                                                    $gte: new Date().toLocaleDateString("en-CA"),
                                                },
                                            },
                                        },
                                        {
                                            doc_type: {
                                                $eq: 'event',
                                            },
                                        },
                                        {
                                            club: {
                                                $or: clubs,
                                            },
                                        },
                                        // {
                                        //     $or: [
                                        //         {
                                        //             hidden: false,
                                        //         },
                                        //         {
                                        //             removed: false,
                                        //         },
                                        //     ],
                                        // },
                                    ],
                                },
                            })
                            .then(({ docs }: any) => {
                                if (docs?.length > 0) {
                                    setOwnerEvents(docs);
                                }
                            })
                            .catch((e: any) => console.error(e));
                    }
                });
        } else {
            setOwnerEvents([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => fetchClubsAndEvents(), []);

    const switchEvent = (event: IEvent) => {
        eventDB().get(event._id).then(doc => dispatch(setEvent(doc)));
        toast({ title: t('EVENT_CHANGED'), position: 'top-end' });
    };

    return (
        <div className={`${className} flex items-center p-3.5 rounded text-white bg-info panel`}>
            <span className="text-white w-6 h-6 mr-4 rtl:ml-4">
                <IconTrophy size={24} />
            </span>
            {
                selectedEvent.name ? <span>
                    <strong className="mr-1 rtl:ml-1">Selected Event: </strong>
                    {selectedEvent.name}
                </span> : <strong>Please select event.</strong>
            }

            <div className="dropdown ml-auto rtl:mr-auto">
                {/* TODO: Change call on click on Change CTA */}
                <Dropdown placement={`${isRtl ? 'bottom-start' : 'bottom-end'}`} btnClassName="btn btn-sm bg-white text-black" button={selectedEvent.name ? <>Change</> : <>Select</>}>
                    <ul className="!min-w-[330px] md:w-max">
                        {ownerEvents.map((event) => {
                            // const selected = selectedEvent._id === event._id;
                            return (
                                <li key={event._id}>
                                    <button type="button" className='text-start' onClick={() => switchEvent(event)}>
                                        {event.name}
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </Dropdown>
            </div>
        </div>
    );
};
