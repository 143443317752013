const {
  VITE_NODE_ENV: ENV,
  VITE_CLOUDFRONT_URL: CLOUDFRONT_URL,
  VITE_SITEWIDE_NOTICE: SITEWIDE_NOTICE,
  VITE_HOST: HOST,
  VITE_DB_URL: DB_URL,
  VITE_ATHLETE_DB: ATHLETE_DB,
  VITE_USN: USN,
  VITE_PSWD: PSWD,
  VITE_CLUB_DB: CLUB_DB,
  VITE_EVENTS_DB: EVENTS_DB,
  VITE_RECORD_DB: RECORD_DB,
  VITE_REQUESTS_DB: REQUESTS_DB,
  VITE_SQUAD_DB: SQUAD_DB,
  VITE_RESULTS_DB: RESULTS_DB
} = import.meta.env;

export {
  ENV,
  CLOUDFRONT_URL,
  SITEWIDE_NOTICE,
  HOST,
  DB_URL,
  ATHLETE_DB,
  CLUB_DB,
  EVENTS_DB,
  RECORD_DB,
  REQUESTS_DB,
  SQUAD_DB,
  RESULTS_DB,
  USN,
  PSWD
}