import { EDIVISIONS } from './divisions';

interface IRules {
    code: ERules;
    name: string;
    scoring: Array<IScoring>;
}

interface IScoring {
    division: 'all' | EDIVISIONS;
    highestScore: number;
    tieScore: string;
}

export enum ERules {
    DCRA = 'DCRA',
    NRAUS = 'NRAUS',
    NRAUK = 'NRAUK',
    ICFRA = 'ICFRA',
    NRAA = 'NRAA',
    WIFSC = 'WIFSC',
}

export const ORules: Array<IRules> = [
    { code: ERules.ICFRA, name: 'ICFRA', scoring: [{ division: 'all', highestScore: 5, tieScore: 'V' }] },
    { code: ERules.DCRA, name: 'DCRA', scoring: [{ division: 'all', highestScore: 5, tieScore: 'V' }] },
    { code: ERules.NRAUS, name: 'NRA of USA', scoring: [{ division: 'all', highestScore: 10, tieScore: 'X' }] },
    { code: ERules.NRAUK, name: 'NRA of UK', scoring: [{ division: 'all', highestScore: 5, tieScore: 'V' }] },
    { code: ERules.WIFSC, name: 'WI Fullbore Shooting Council', scoring: [{ division: 'all', highestScore: 5, tieScore: 'V' }] },
    {
        code: ERules.NRAA,
        name: 'NRA of Australia',
        scoring: [
            { division: EDIVISIONS.TR, highestScore: 5, tieScore: 'V' },
            { division: EDIVISIONS.FOPEN, highestScore: 6, tieScore: 'X' },
            { division: EDIVISIONS.FTR, highestScore: 6, tieScore: 'X' },
            { division: EDIVISIONS.FS, highestScore: 6, tieScore: 'X' },
        ],
    },
];
