import { Dialog, Transition } from '@headlessui/react';
import Tippy from '@tippyjs/react';
import { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'tippy.js/dist/tippy.css';
import { IEvent, fetchSession } from '../../db';
import CreateEvent from '../../pages/CreateEvent';
import { IconClose, IconPencil } from '../Icons';
import { useErrorAlerts } from "../Alerts/useErrorAlerts";

interface Props {
    event: IEvent;
    fetchEvents: Function;
    inline?: boolean;
}

export const EditEvent: FC<Props> = ({ event, fetchEvents, inline }) => {
    const { t } = useTranslation();
    const [isOpen, openModal] = useState(false);
    const handleError = useErrorAlerts();

    function closeModal(value: boolean) {
        if (value === false) {
            openModal(false);
            fetchEvents();
        }
    }

    useEffect(() => {
        if (isOpen) {
            fetchSession().catch((e) => handleError(e, 'edit_event_auth'));
        }
    }, [isOpen]);

    return (
        <>
            <Tippy content={t('EDIT_EVENT')} theme="info">
                <button onClick={() => openModal(true)} type="button" className="ms-2 text-warning">
                    <IconPencil className="block m-auto" />
                    {!inline && <div className="mt-2">Edit</div>}
                </button>
            </Tippy>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" open={isOpen} onClose={() => openModal(true)}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="panel border-0 p-0 rounded-lg overflow-hidden w-full max-w-5xl my-8 text-black dark:text-white-dark">
                                    <>
                                        <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                            <div className="font-bold text-lg">Edit Event</div>
                                            <button type="button" onClick={() => openModal(false)} className="text-white-dark hover:text-dark">
                                                <IconClose />
                                            </button>
                                        </div>
                                        <CreateEvent event={event} closeModal={closeModal} edit />
                                    </>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
